<template>
  <div id="signup">
    <tmpl_header :hide="['search', 'nav', 'shopcart']" subtitle="欢迎注册" />

    <div class="toplink">
      <router-link class="bright" to="/signin">已有账号，马上登录</router-link>
    </div>

    <div class="form form-label-block">
      <div class="form-group" :class="{ err: err.mobile }">
        <small v-if="err.mobile">{{ err.mobile }}</small>
        <div>
          <span class="prefix icon icon_mobile"></span>
          <input
            type="text"
            v-model="mobile"
            autocomplete="new-text"
            placeholder="请输入手机号码"
          />
        </div>
      </div>
      <div class="form-group" :class="{ err: err.verify }">
        <small v-if="err.verify">{{ err.verify }}</small>
        <div>
          <span class="prefix icon icon_safe"></span>
          <input
            type="text"
            v-model="verify"
            autocomplete="new-text"
            placeholder="请输入验证码"
          />
          <button class="suffix" @click="sendSms">
            {{ send > 0 ? send + "秒后可重发" : "获取短信验证码" }}
          </button>
        </div>
      </div>
      <div class="form-group" :class="{ err: err.password }">
        <small v-if="err.password">{{ err.password }}</small>
        <div>
          <span class="prefix icon icon_lock"></span>
          <input
            type="password"
            v-model="password"
            autocomplete="new-password"
            placeholder="请输入密码"
          />
        </div>
      </div>
      <div class="form-group" :class="{ err: err.vpassword }">
        <small v-if="err.vpassword">{{ err.vpassword }}</small>
        <div>
          <span class="prefix icon icon_lockv"></span>
          <input
            type="password"
            v-model="vpassword"
            autocomplete="new-password"
            placeholder="请再输入一遍密码"
          />
        </div>
      </div>

      <div class="form-group" style="padding-top: 30px">
        <button class="btn" :disabled="sending" @click="register">
          {{ sending ? "注册中" : "提交注册" }}
        </button>
      </div>
    </div>

    <tmpl_footer style="margin-top: 0"></tmpl_footer>
  </div>
</template>

<script>
import api from "../../api/index";
import apis from "../../api/api";
import store from "../../store/index";
import tmpl_header from "../../components/common/header.vue";
import tmpl_footer from "../../components/common/footer.vue";

export default {
  data() {
    return {
      loading: true,

      mobile: "",
      vmobile: "",
      verify: "",
      password: "",
      vpassword: "",
      err: {
        mobile: "",
        verify: "",
        password: "",
        vpassword: "",
      },

      send: 0,
      sendTime: 0,
      sending: false,
    };
  },

  components: {
    tmpl_header,
    tmpl_footer,
  },

  created: function () {},

  methods: {
    sendSms: function () {
      const moblieReg = /^[1][3,4,5,7,8,9][0-9]{9}$/;

      if (this.send) return;

      if (!moblieReg.test(this.mobile)) {
        return (this.err.mobile = "手机号码不正确");
      }

      this.vmobile = this.mobile;

      return api.post(apis.sms.send, { phoneNumber: this.mobile }).then(() => {
        this.send = 300;
        this.sendTime = new Date().getTime();
        this.doTimer();
      });
    },

    doTimer: function () {
      const that = this;
      setTimeout(function () {
        const now = new Date().getTime();
        const delt = 300 - Math.floor((now - that.sendTime) / 1000);
        if (delt > 0) {
          if (that.send != delt) that.send = delt;

          that.doTimer();
        } else {
          that.send = 0;
        }
      }, 100);
    },

    register: function () {
      const page = this;
      const moblieReg = /^[1][3,4,5,7,8,9][0-9]{9}$/;

      if (page.sending) return;
      page.sending = true;

      if (!moblieReg.test(page.mobile)) {
        return (page.err.mobile = "手机号码不正确");
      }

      if (!page.verify) {
        return (page.err.mobile = "请输入验证码");
      }

      if (page.vmobile != page.mobile) {
        return (page.err.mobile = "修改号码后请重新获取验证码");
      }

      if (!page.password || page.password.length < 6) {
        return (page.err.password = "密码长度不得小于6位");
      }

      if (page.password != page.vpassword) {
        return (page.err.vpassword = "两次密码输入不一致");
      }

      return api
        .post(apis.common.signup, {
          phoneNumber: page.mobile,
          password: page.password,
          verifyCode: page.verify,
        })
        .then(() => {
          page.mobile = "";
          page.verify = "";
          page.password = "";
          page.vpassword = "";
          page.sending = false;
          store.dispatch("alert", {
            icon: "success",
            timer: 0,
            msg: "注册成功",
            info: "账号已注册成功，您可以登录后继续访问",
          });
        })
        .catch((err) => {
          page.sending = false;
          if (err.data && err.data.data) {
            if (err.data.code === 5108) page.err.mobile = err.data.data;
            else store.dispatch("toast", err.data.data);
          } else {
            return store.dispatch("toast", err.data.message);
          }
        });
    },
  },
};
</script>

<style lang="less">
@import url("../../assets/less/common.less");
@import url("../../assets/less/form.less");
@import url("./up.less");
</style>
